<template>
  <div class="my-6 flex items-center justify-between">
    <div>
      <h3 class="font-bold">
        Editar usuário > <span class="text-primary">{{ user.nome }}</span>
      </h3>

      <span class="text-sm text-gray-500"
        >Verifique as informações abaixo e clique no botão <b>salvar</b> para
        editar para confirmar as modificações.
      </span>
    </div>
    
  </div>

<div class="flex justify-end my-5">
      <Button @click="edit" color="primary" text="Salvar" />
    </div>

  <div class="card rounded-lg bg-white shadow-sm border p-8">
    <div class="mb-6">
      <span class="text-sm text-gray-500">Informações básicas: </span>
    </div>

    <div class="flex">
      <div class="flex-1 mr-3">
        <Input
          v-model="user.nome"
          type="default"
          label="Nome"
          placeholder="Nome do Usuário"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="user.email"
          type="default"
          label="Email"
          placeholder="Email"
          :readonly="true"
          autocomplete="off"
        />
      </div>

      <div class="flex-1">
        <Input
          v-model="user.tipo"
          type="select"
          :data="[
            { label: 'Adminstrador', value: 'ADM' },
            { label: 'Usuário', value: 'USER' },
          ]"
          label="Tipo"
          placeholder="Selecione um tipo"
        />
      </div>
    </div>

    <div class="flex mt-5">
      <div class="flex-1 ">
        <Input
          v-model="user.status"
          type="select"
          :data="[
            { label: 'Ativo', value: 'ATIVADO' },
            { label: 'Inativo', value: 'DESATIVADO' },
          ]"
          label="Status"
          placeholder="Selecione um status"
        />
      </div>
  </div>

  </div>


<!-- <div class="card rounded-lg bg-white shadow-sm border p-8 mt-5">
    <div class="flex w-full">
      <div class="flex-1 mr-3" v-if="assinatura">
        <Input
          v-model="assinatura.planos_id"
          type="select"
          :data="planos"
          label="Planos"
        />
      </div>

      <div class="flex-1">
        <Input
          v-model="assinatura.validade"
          type="date"
          label="Validade do plano"
          placeholder="Validade do plano"
        />
      </div>
    </div>

</div> -->
<!-- 
<div class="card rounded-lg bg-white shadow-sm border p-8 mt-5">
    <div class="flex flex-col w-full">
      <div class="mb-6 flex flex-row justify-between items-center">
        <span class="text-sm text-gray-500">Perfis do usuário: </span>
        <Button @click="createProfile" color="primary" text="Criar novo perfil" />
      </div>
      <DataTable
        :headers="headers"
        :data="profiles"
        :options="options"
        color="primary"
      />
    </div>

</div> -->
  

  <!-- <div v-if="profiles.length > 0" class="card rounded-lg shadow-sm p-8 mt-5">
    <div class="flex w-full">
      <div :class="`${previewProfile ? 'w-1/2' : 'w-full'}`" id="perfis">
        <div class="mb-6">
          <span class="text-sm text-gray-500">Perfis do usuário: </span>
        </div>

        <div class="flex justify-start flex-wrap">
          <template v-for="profile in profiles" :key="profile.id">
            <div class="border py-5 pl-5 w-100 flex items-center justify-between rounded-md bg-white">
              <div>
                    <p class="font-bold text-md mr-6 text-gray-500 capitalize" href="#">
                      {{ profile.titulo }}
                    </p>
                    <p class="text-sm">{{toDate(profile.created_at)}}</p>
              </div>
              <div @click="previewProfile = profile" class="mr-6 bg-green-200 border-green-500 border-solid ml-20  px-3 py-4 rounded-md">
                <i class="gg-eye text-green-500 hover:text-green-400 cursor-pointer"></i>
              </div>
            </div>
          </template>
        </div>



      </div>
    </div>
  </div> -->
  <!-- <div
    v-if="modalProfile"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <div
      x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2"
      x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150"
      x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      @keydown.escape="closeModal"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog"
      id="modal"
    >
      <header class="flex justify-end">
        <button
          @click="closeModal"
          class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close"
        >
          <svg
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            role="img"
            aria-hidden="true"
          >
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
              fill-rule="evenodd"
            ></path>
          </svg>
        </button>
      </header>
      <div class="mt-4 mb-6">
        <p class="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300" v-if="profileSelecionado.id">
          Editar perfil
        </p>
        <p class="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300" v-else>
          Criar novo perfil
        </p>
        <div class="flex w-full mt-8">
          <div class="flex-1 mr-3">
            <Input
              v-model="profileSelecionado.nome"
              type="default"
              label="Nome"
              placeholder="Nome de perfil"
            />
          </div>

          <div class="flex-1">
            <Input
              v-model="profileSelecionado.instagram"
              type="default"
              label="Instagram"
              placeholder="Perfil do instagram"
            />
          </div>
        </div>
      </div>
      <footer
        class="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800"
      >
        <button
          @click="closeModal"
          class="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
        >
          Cancelar
        </button>
        <button
          v-if="profileSelecionado.id"
          @click="editProfile"
          class="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-primary border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-primary focus:outline-none focus:shadow-outline-purple"
        >
          Editar
        </button>
        <button
          v-else
          @click="addProfile"
          class="w-full px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-primary border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-primary focus:outline-none focus:shadow-outline-purple"
        >
          Criar
        </button>
      </footer>
    </div>
  </div> -->


  


  <!-- MODAL --> 
<!-- <div v-if="previewProfile" class="modal opacity-1  fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
    <div class="modal-container bg-white w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
    <div class="bg-primary py-4 flex justify-between items-center px-4">
      <i class="gg-arrow-left text-white" @click="previewProfile = null"></i>
      <span class="text-white">Detalhes do perfil</span>
      <i class="gg-more-vertical-alt mr-4 text-white"></i>
    </div>
      <div class="modal-content py-2 text-left px-6">
        <div class="py-10 flex items-center justify-center flex-col">
          <img src="https://content.api.news/v3/images/bin/a6923adbc7bece73803221613f410782" class="rounded-full img-profile" />
          <span class="mt-3">Nome</span>
        </div>
      </div>
      <div class="stories flex overflow-x-scroll">
      <template v-for="item in 10" :key="item">
        <img @click="previewStory = item" src="https://content.api.news/v3/images/bin/a6923adbc7bece73803221613f410782" class="rounded-full img-storie" />
      </template>
      </div>
        <div class="posts flex mt-6 flex-wrap overflow-y-auto">
      <template v-for="item in 32" :key="item">
        <img @click="previewPost = item"  src="https://content.api.news/v3/images/bin/a6923adbc7bece73803221613f410782" class="w-1/3 img-post" />
      </template>
      </div>
    </div>
  </div>


  <div v-if="previewPost" class="modal opacity-1  fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
    <div class="modal-container bg-white w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
    <div class="bg-primary py-4 flex justify-between items-center px-4">
      <i class="gg-arrow-left text-white" @click="previewPost=null"></i>
      <span class="text-white">Detalhes da Publicação</span>
      <i class="gg-more-vertical-alt mr-4 text-white"></i>
    </div>


      <div class="modal-content py-2 text-left px-6">
        <div class="py-5 flex items-center justify-center flex-col">
          <img src="https://content.api.news/v3/images/bin/a6923adbc7bece73803221613f410782" class="w-full rounded-md" />
         
        </div>
        <h1 class="mt-3 text-lg">Lorem ipsum</h1>
        <p class="text-sm text-gray-500">12/04/2021</p>
        <p class="text-sm pb-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae est finibus magna aliquam pellentesque. Fusce gravida eu elit at consequat. Donec nec mauris enim. Donec fringilla quis leo ut eleifend. In porta, libero vitae efficitur cursus, justo nulla elementum sem, sed porta magna ante in arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae est finibus magna aliquam pellentesque. Fusce gravida eu elit at consequat. Donec nec mauris enim. Donec fringilla quis leo ut eleifend. In porta, libero vitae efficitur cursus, justo nulla elementum sem, sed porta magna ante in arcu.</p>
      </div>
    </div>
  </div>

  <div v-if="previewStory" class="modal opacity-1  fixed w-full h-full top-0 left-0 flex items-center justify-center">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
    <div class="modal-container bg-white w-1/2 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
    <div class="bg-primary py-4 flex justify-between items-center px-4">
      <i class="gg-arrow-left text-white" @click="previewStory=null"></i>
      <span class="text-white">Detalhes do storie</span>
      <i class="gg-more-vertical-alt mr-4 text-white"></i>
    </div>


      <div class="modal-content  text-left ">
        <div class="flex items-center justify-center flex-col">
          <img src="https://content.api.news/v3/images/bin/a6923adbc7bece73803221613f410782" class="w-full" />
         
        </div>
       </div>
    </div>
  </div> -->

  
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
// import DataTable from "../../components/Elements/Datatable.vue";
import { GET, PUT, } from "../../services/api";
// import { toDate, toDateUS } from "../../services/formater";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "editUsers",
  components: {
    Input,
    Button,
    // DataTable
  },
  setup() {
    // const headers = [
    //   { key: "Id", value: "id" },
    //   { key: "Nome", value: "nome" },
    //   { key: "Instagram", value: "instagram" },
    //   { key: "Options", value: "" },
    // ];


    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const user = ref({
      "nome": "",
      "email": "",
      "tipo": "",
      "status": "",
      "created_at": "",
      "updated_at": "",
      "perfis_count": 0,
    });
    // const assinatura = ref({
    //   "id": "",
    //   "planos_id": "",
    //   "validade": "",
    //   "users_id": ""
    // })
    // const planos = ref({});
    // const profiles = ref([]);
    // const modalProfile = ref(false);
    // const profileSelecionado = ref({});
    // const previewProfile = ref(null);
    // const previewPost = ref(null);
    // const previewStory = ref(null);

    onMounted(async () => {
      loader.open();
      user.value = await GET(`users/${route.params.id}`);
      // planos.value = await GET(`planos`);
      // planos.value = planos.value.map(el => {
      //   return {label: el.nome, value: el.id}
      // })
      // if (user.value.assinatura[0]) {
      //   assinatura.value = user.value.assinatura[0]
      //   assinatura.value.id = user.value.assinatura[0].id
      //   assinatura.value["planos_id"] = user.value.assinatura[0].planos_id
      //   assinatura.value.validade = toDateUS(assinatura.value.validade);
      // }
      // profiles.value = user.value.perfis;
      // console.log(profiles.value);
      loader.close();
    });

    // const createOrUpdateSubscribe = async () => {
    //   //Criar assinatura caso o usuário não tenha
    //   loader.open();
    //   if(user.value.assinatura[0]){
    //     console.log(user.value.assinatura[0])
    //     try{
    //       await PUT(`assinaturas/${user.value.assinatura[0].id}`, {
    //         "validade": toDateUS(assinatura.value.validade),
    //         "planos_id": assinatura.value.planos_id,
    //         "users_id": user.value.id
    //       })
    //       loader.close();
    //     }catch(e){
    //       loader.close();
    //       alert.open("Atenção!", e, "warning");
    //     } 
    //   }else{
    //     try{
    //       await POST("assinaturas", {
    //         "validade": toDateUS(assinatura.value.validade),
    //         "planos_id": assinatura.value.planos_id,
    //         "users_id": user.value.id
    //       })
    //       loader.close();
    //     }catch(e){
    //       loader.close();
    //       alert.open("Atenção!", e, "warning");
    //     }        
    //   }
    // }

    // const setPreview = (profile) => {
    //   previewProfile.value = profile;
    // };

    const edit = async () => {
      //title: string, message: string, type: string, confirmMessage: string, callbackOK: Function, callbackCancel: Function | void
      const validate = isEmpty(user.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar esse item?",
          "warning",
          "Sim",
          async () => {
            try {
              //createOrUpdateSubscribe()
              loader.open();
              const response = await PUT(`users/${route.params.id}`, user.value);
              alert.open(
                "Sucesso!",
                `${response.nome} foi editado com sucesso!`,
                "success"
              );
              loader.close();
              router.push("/usuarios");
            } catch (e) {
              loader.close();
              alert.open("Atenção!", `${e.charAt(0).toUpperCase()}${e.slice(1)}`, "warning");
            }
          },
          modal.close()
        );
      }
    };

    // const closeModal = () => {
    //   modalProfile.value = false;
    // }

    // const createProfile = () => {
    //   modalProfile.value = true;
    //   profileSelecionado.value = {};
    // }

    // const addProfile = async() => {
    //   const validate = isEmpty(profileSelecionado.value);
    //   if (validate) {
    //     alert.open("Atenção!", validate, "warning");
    //   } else {
    //     loader.open();
    //     profileSelecionado.value['users_id'] = user.value.id;
    //     try {
    //       const response = await POST("perfis", profileSelecionado.value);
    //       profiles.value.push(response);
    //       profileSelecionado.value = {};
    //       alert.open(
    //         "Sucesso!",
    //         `Perfil <b>${response.nome}</b> criado com sucesso!`,
    //         "success"
    //       );
    //       loader.close();
          
    //     } catch (e) {
    //       alert.open("Atenção!", e, "danger");
    //       loader.close();
    //     }
    //   }
    //   modalProfile.value = false;
    // }

    // const deleteProfile = (profile) => {
    //   const deleteFunction = async function() {
    //     loader.open();
    //     try{
    //       await DELETE(`perfis/${profile.id}`);
    //       profiles.value = profiles.value.filter((item) => item.id != profile.id);
    //       alert.open("Sucesso!", `Perfil deletado com sucesso!`, "success");
    //       loader.close();
    //     }catch(e){
    //       alert.open("Erro", e, "danger");
    //       loader.close();
    //     }
    //   }
    //   modal.open(
    //     "Atenção",
    //     "Deseja realmente excluir esse perfil?",
    //     "warning",
    //     "Sim",
    //     () => deleteFunction(),
    //     modal.close()
    //   );
    // };

    // const showProfile = (r) => {
    //   modalProfile.value = true;
    //   profileSelecionado.value = r;
    // };

    // const editProfile = async () => {
    //   loader.open();
    //   profileSelecionado.value['users_id'] = user.value.id;
    //   try {
    //     const response = await PUT(`perfis/${profileSelecionado.value.id}`, profileSelecionado.value);
    //     profiles.value.map((prof) => {
    //       prof.id === response.id ? prof = response : null
    //     })
    //     profileSelecionado.value = {};
    //     alert.open(
    //       "Sucesso!",
    //       `Perfil <b>${response.nome}</b> criado com sucesso!`,
    //       "success"
    //     );
    //     loader.close();
        
    //   } catch (e) {
    //     alert.open("Atenção!", e, "danger");
    //     loader.close();
    //   }
      
    //   modalProfile.value = false;
    // }

    // const options = [
    //   { icon: "pen", action: (param) => showProfile(param) },
    //   { icon: "trash", action: (param) => deleteProfile(param) },
    // ];


    return { 
      edit, 
      user, 
      // profiles, 
      // toDate, 
      // setPreview, 
      // previewProfile,
      // previewStory,
      // previewPost, 
      // planos, 
      // assinatura, 
      // headers, 
      // options, 
      // createProfile,
      // addProfile,
      // showProfile, 
      // editProfile,
      // deleteProfile,
      // modalProfile,
      // profileSelecionado,
      // closeModal,
    };
  },
};
</script>

<style>
.img-profile{
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.img-storie{
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 0px 10px;
  cursor: pointer;
  user-select: contain;
}

.posts{
  height: 33vh;
}

.posts::-webkit-scrollbar {
  display: none; 
}

.img-post{
  border: solid 1px white;
  inset: 2;
}




.stories{
  -webkit-overflow-scrolling: scroll;
}


.stories::-webkit-scrollbar {
  display: none; 
}
</style>