<template>
  <div class="my-6">
    <h3 class="font-bold">Criar um novo usuário</h3>
    <span class="text-sm text-gray-500"
      >Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
      as alterações.
    </span>
  </div>

  <div class="card shadow-sm bg-white border p-8">
    <div class="flex">
      <div class="flex-1 mr-3">
        <Input
          v-model="user.nome"
          type="default"
          label="Nome"
          placeholder="Nome do Usuário"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="user.email"
          type="default"
          label="Email"
          placeholder="Email"
          autocomplete="off"
        />
      </div>

      <div class="flex-1">
        <Input
          v-model="user.tipo"
          type="select"
          :data="[
            { label: 'Adminstrador', value: 'ADM' },
            { label: 'Usuário', value: 'USER' },
          ]"
          label="Tipo"
          placeholder="Selecione um tipo"
        />
      </div>
    </div>

    <div class="flex mt-5">
      <div class="flex-1 mr-3">
        <Input
          v-model="user.password"
          type="password"
          placeholder="Senha"
          label="Senha"
          autocomplete="off"
        />
      </div>
      <div class="flex-1 mr-3">
        <Input
          v-model="user.status"
          type="select"
          :data="[
            { label: 'Ativo', value: 'ATIVADO' },
            { label: 'Inativo', value: 'DESATIVADO' },
          ]"
          label="Status"
          placeholder="Selecione um status"
        />
      </div>
    </div>
  </div>
  <!-- <div class="card rounded-lg bg-white shadow-sm border p-8 mt-5">
    <div class="flex w-full">
      <div class="flex-1 mr-3" v-if="assinatura">
        <Input
          v-model="assinatura.planos_id"
          type="select"
          :data="planos"
          label="Planos"
        />
      </div>

      <div class="flex-1">
        <Input
          v-model="assinatura.validade"
          type="date"
          label="Validade do plano"
          placeholder="Validade do plano"
        />
      </div>
    </div>
  </div> -->
  <div class="flex justify-end mt-5">
    <Button @click="create" color="primary" text="Criar" />
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref } from "vue";
import { POST } from "../../services/api";
import { isEmpty, isEmail } from "../../services/validate";
import { useRouter } from "vue-router";

export default {
  name: "createUser",
  components: {
    Input,
    Button,
  },

  setup() {
    const alertProps = inject("alert");
    const assinatura = ref({
      validade: "",
      "planos_id": "",
      "users_id": "",
    });
    const user = ref({
      nome: "",
      email: "",
      password: "",
      tipo: "0",
      status: "0",
    });
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();

    const createSubscribe = async (obj) => {
      try {
        loader.open();
        const sub = await POST("assinaturas", obj);
        loader.close();
        router.push("/usuarios");
        return sub;
      } catch (e) {
        loader.close();
        alert.open("Atenção!", e, "warning");
      }
    };

    const createUser = async () => {
      const validate = isEmpty(user.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        if (isEmail(user.value.email)) {
          loader.open();
          try {
            const response = await POST("users", user.value);
            alert.open(
              "Sucesso!",
              `Usuário <b>${response.nome}</b> criado com sucesso!`,
              "success"
            );
            loader.close();
            return response;
          } catch (e) {
            alert.open("Atenção!", `${e.charAt(0).toUpperCase()}${e.slice(1)}`, "danger");
            loader.close();
          }
        } else {
          alert.open(
            "Atenção!",
            `O email <b>${user.value.email}</b> não é válido!`,
            "warning"
          );
        }
      }
    };

    const create = async () => {
      const u = await createUser();
      if(u){
        const sub = await createSubscribe({
          validade: '2030-10-18',
          "planos_id": 1,
          "users_id": u.id,
        });
        return sub;
      }
    };

    return {
      alertProps,
      user,
      create,
      assinatura,
    };
  },
};
</script>

<style></style>
