
import { onMounted } from "vue";
import Chart from "chart.js";
interface Props {
  chartId?: string | undefined;
  type?: string | undefined;
  color?: string | undefined;
  label?: string | undefined;
  labels?: Array<any> | undefined;
  data?: Array<any> | undefined;
}
export default {
  props: {
    chartId: String || undefined,
    type: String,
    color: String,
    label: String,
    labels: Array,
    data: Array
  },
  setup(props: Props) {
    onMounted(() => {
      const { chartId }: Props = props;
      const canvas = document.getElementById(
        chartId || ""
      ) as HTMLCanvasElement;

      new Chart(canvas, {
        type: props.type,
        data: {
          labels: props.labels,
          datasets: [
            {
              label: `Quanidade de ${props.label}`,
              data: props.data,
              backgroundColor: props.type == 'line' ? ['rgba(0,0,0,0)'] : ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`${props.color}`],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    });

    return props;
  },
};
