

const List = [
    { icon: 'home', label: 'Dashboard', name: "Home", path: '/',  component: () => import('../views/Home.vue'),},

    { icon: 'user', label: 'Usuários', name:"Usuários", path: '/usuarios',  component: () => import('../views/Usuarios/index.vue') },
    { show: false, icon: 'user', label: 'Usuários', name:"Usuários/Criar", path: '/usuarios/criar',  component: () => import('../views/Usuarios/create.vue') },
    { show: false, icon: 'user', label: 'Usuários', name:"Usuários/Editar", path: '/usuarios/edit/:id?',  component: () => import('../views/Usuarios/update.vue') },

    //Dicas
    { icon: 'file-document', label: 'Dicas de Publicação', name:"Dicas", path: '/dicas',  component: () => import('../views/Dicas/index.vue')},
    { show: false, name:"Dicas/Criar", path: '/dicas/criar',  component: () => import('../views/Dicas/create.vue') },
    { show: false, name:"Dicas/Editar", path: '/dicas/edit/:id?',  component: () => import('../views/Dicas/update.vue') },

    //Posts
    // { icon: 'polaroid', label: 'Publicações', name:"Publicações", path: '/publicacoes',  component: () => import('../views/Posts/index.vue')},
    // { show: false, name:"Publicações/Criar", path: '/publicacoes/criar',  component: () => import('../views/Posts/create.vue') },
    // { show: false, name:"Publicações/Editar", path: '/publicacoes/edit/:id?',  component: () => import('../views/Posts/update.vue') },


 


    //Planos
    // { icon: 'shopping-cart', label: 'Planos', name:"Planos", path: '/planos',  component: () => import('../views/Planos/index.vue') },
    // { show: false, name:"Planos/Criar", path: '/planos/criar',  component: () => import('../views/Planos/create.vue') },
    // { show: false, name:"Planos/Editar", path: '/planos/edit/:id?',  component: () => import('../views/Planos/update.vue') },

   //Dicas-Darias
   { icon: 'calendar', label: 'Eventos Diários', name:"Eventos Diários", path: '/eventos-diarios',  component: () => import('../views/EventosDiarios/index.vue')},
   { show: false, name:"Eventos Diários/Criar", path: '/eventos-diarios/criar',  component: () => import('../views/EventosDiarios/create.vue') },
   { show: false, name:"Eventos Diários/Editar", path: '/eventos-diarios/edit/:id?',  component: () => import('../views/EventosDiarios/update.vue') },


]

export default List;