<template>
  <div class="my-6">
    <h3 class="font-bold">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500"
      >Aqui você pode observar todos os itens relacionados aos usuários como
      suas respectivas ações.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <router-link to="/usuarios/criar">
      <Button color="primary" text="Criar novo usuário" />
    </router-link>
  </div>
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      :exports="true"
      color="primary"
    />
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "users",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Nome", value: "nome" },
      { key: "Email", value: "email" },
      { key: "Tipo", value: "tipo"},
      // { key: "Quantidade de Perfis", value: "perfis_count" },
      // { key: "Plano", value: "['assinatura']['0']['nome']", deep: true},
      // { key: "Validade", value: "['assinatura']['0']['validade']", deep: true, date: true},
      {
        key: "Status",
        value: "status",
        badge: { ATIVADO: "green", DESATIVADO: "red" },
      },
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("users_adm");
        loader.close();
      } catch (e) {
        if(e === "Token é inválido") {
          router.push({ path: '/login'});
        }
        loader.close();
        alert.open("Erro", `${e.charAt(0).toUpperCase()}${e.slice(1)}`, "danger");
      }
    });

    const deleteItem = (user) => {
      loader.open();
      const deleteFunction = async function() {
        try {
          loader.open();
          await DELETE(`users/${user.id}`);
          data.value = data.value.filter((item) => item.id != user.id);
          loader.close();
          alert.open("Sucesso!", `Usuário deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", `${e.charAt(0).toUpperCase()}${e.slice(1)}`, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse item?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    const show = (r) => {
      router.push(`/usuarios/edit/${r.id}`);
    };

    const options = [
      { icon: "pen", action: (param) => show(param) },
      { icon: "trash", action: (param) => deleteItem(param) },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style></style>
