<template>
  <div class="home">
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Dashboard
    </h2>
    <!-- <Alert :label="`Você recebeu mais de ${badges.mes} visitas no último mês.`" icon="danger" color="primary" @click="event" /> -->
    <div class="flex flex-wrap justify-between">
      <div class="flex-1 mr-3">
        <Card
          icon="log-in"
          :number="badges.visitas_dia"
          label="Visitas no dia"
          color="green"
        />
      </div>
      <div class="flex-1 mr-3 ml-3">
        <Card
          icon="calendar"
          :number="badges.visitas_mes"
          label="Visitas no mês"
          color="gray"
        />
      </div>
      <div class="flex-1 mr-3 ml-3">
        <Card
          icon="user"
          :number="badges.users"
          label="Usuários"
          color="purple"
        />
      </div>
      <div class="flex-1 ml-3">
        <Card
          icon="file-document"
          :number="badges.dicas"
          label="Dicas"
          color="orange"
        />
      </div>
    </div>

    <hr class="my-9" />
    <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
      <div class="w-full">
        <div class="flex-3 w-full">
          <h3 class="font-bold">Gráficos de Inteligência</h3>
          <span class="text-sm text-gray-500">
            Aqui você pode observar todos os dados dos
            usuários buscados pelo sistema.
          </span>
        </div>

        <div class="my-9 flex items-center">
          <div class="flex-1 mr-2">
            <Input
              type="date"
              label="Data Inicial"
              v-model="dataInicial"
              :maxDate="dataFinal"
            />
          </div>          
          <div class="flex-1 ml-2">
            <Input
              type="date"
              label="Data Final"
              v-model="dataFinal"
              :minDate="dataInicial"
            />
          </div>
          <div class=" ml-4 items-center">
            <button
              type="button"
              :class="`btn-outline-primary 
              transition duration-300 ease-in-out 
              bg-primary text-white
              focus:outline-none focus:shadow-outline border
              font-bold py-5 mt-1 w-5/4 px-10 text-sm rounded-md items-center h-full`"
              @click="filtrarGraficos"
            >
              Filtrar 
            </button>
            
          </div>
        </div>

        <div>
          <div id="graphs" class="flex w-full my-5">
            <div class="flex-1  w-1/3">
              <p class="text-sm text-center">Modo de acesso</p>
              <Chart
                chartId="1"
                type="doughnut"
                :labels="['Online', 'Offline']"
                :data="modoAcesso"
                color="#389897"
              />
            </div>
            <div class="flex-1">
              <p class="text-sm text-center">Plataforma</p>
              <Chart
                chartId="2"
                type="doughnut"
                :labels="['Android', 'Apple']"
                :data="plataforma"
                color="#389897"
              />
            </div>
            <div class="flex-1 ">
              <p class="text-sm text-center">Modelo</p>
              <Chart
                chartId="3"
                type="doughnut"
                :labels="['Celular', 'Tablet', 'Ipad']"
                :data="modelo"
                color="#389897"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="hidden md:block mt-8">
      <div v-if="data.graphs.length > 0" class="flex grid-rows-1">
        <div class="flex-1" key="1" id="1">
          <p class="text-sm text-center">{{data.graphs[0].name}}</p>
          <Chart chartId="12" :type="data.graphs[0].type" :labels="data.graphs[0].labels" :data="data.graphs[0].values" color="#389897aa" :label="data.graphs[0].name"/>
        </div>
        <div class="flex-1" >
          <p class="text-sm text-center">{{data.graphs[1].name}}</p>
          <Chart chartId="13" :type="data.graphs[1].type" :labels="data.graphs[1].labels" :data="data.graphs[1].values" color="#389897aa" :label="data.graphs[1].name"/>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, ref } from "vue";
// import Alert from "../components/Elements/Alert.vue";
import Card from "../components/Elements/Card.vue";
import Input from "../components/Elements/Input.vue";
import Chart from "../components/Chart/Chart.vue";
import ChartJs from "chart.js";
import { GET } from "../services/api";
interface LoadImplements {
  open: Function;
  close: Function;
}

export default defineComponent({
  name: "Home",
  components: {
    Input,
    Card,
    Chart,
  },
  setup() {
    const event = () => alert("ok");
    const loader: LoadImplements = inject("loading") as LoadImplements;

    const badges = ref({
      ['visitas_dia']: "",
      dicas: "",
      labels: [],
      ['visitas_mes']: "",
      users: "",
      graficos: {},
    });
    const dataInicial = ref("");
    const dataFinal = ref("");
    const modoAcesso = ref([0,0]);
    const plataforma = ref([0,0]);
    const modelo = ref([0,0, 0]);

    const optionsGrafico = 
    {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }


    const reconstroiGraficos = () => {

      const graficoFaturamento = document.getElementById(
        '1' || "" 
      ) as HTMLCanvasElement;
      
      new ChartJs(graficoFaturamento, {
        type: 'doughnut',
        data: {
          labels: ['Online', 'Offline'],
          datasets: [
            {
              data: modoAcesso.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });


      const graficoTicket = document.getElementById(
        '2' || "" 
      ) as HTMLCanvasElement;
      
      new ChartJs(graficoTicket, {
        type: 'doughnut',
        data: {
          labels: ['Android', 'Apple'],
          datasets: [
            {
              data: plataforma.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });



      const graficoClientes = document.getElementById(
        '3' || ""
      ) as HTMLCanvasElement;
      new ChartJs(graficoClientes, {
        type: 'doughnut',
        data: {
          labels: ['Celular', 'Tablet', 'Ipad'],
          datasets: [
            {
              data: modelo.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });

    }

    onMounted(async () => {
      loader.open();
      const dataAtual = new Date();
      const dataAtualArray = dataAtual.toLocaleDateString().split("T")[0].split("/");
      dataInicial.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-01`;
      dataFinal.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-${dataAtualArray[0]}`;
      try {
        const response = await GET(`dashboard/${dataInicial.value}/${dataFinal.value}`);
        badges.value = response;
        modoAcesso.value = [response.graficos.ONLINE, response.graficos.OFFLINE]
        plataforma.value = [response.graficos.ANDROID, response.graficos.APPLE]
        modelo.value = [response.graficos.CELULAR, response.graficos.TABLET, response.graficos.IPAD]
        reconstroiGraficos();
        loader.close();
      } catch (e) {
        loader.close();
        console.log(e);
      }
    });

    const filtrarGraficos = async () => {
      loader.open();
      try {
        const response = await GET(`dashboard/${dataInicial.value}/${dataFinal.value}`);
        modoAcesso.value = [response.graficos.ONLINE, response.graficos.OFFLINE]
        plataforma.value = [response.graficos.ANDROID, response.graficos.APPLE]
        modelo.value = [response.graficos.CELULAR, response.graficos.TABLET, response.graficos.IPAD]
        reconstroiGraficos();
        loader.close();
      } catch(e) {
        loader.close();
        console.log(e);
      }
      console.log(dataInicial.value)
      console.log(dataFinal.value)
    }

    return { event, badges, dataInicial, dataFinal, filtrarGraficos, modoAcesso, plataforma, modelo };
  },

  
});
</script>
