
import { defineComponent, inject, onMounted, ref } from "vue";
// import Alert from "../components/Elements/Alert.vue";
import Card from "../components/Elements/Card.vue";
import Input from "../components/Elements/Input.vue";
import Chart from "../components/Chart/Chart.vue";
import ChartJs from "chart.js";
import { GET } from "../services/api";
interface LoadImplements {
  open: Function;
  close: Function;
}

export default defineComponent({
  name: "Home",
  components: {
    Input,
    Card,
    Chart,
  },
  setup() {
    const event = () => alert("ok");
    const loader: LoadImplements = inject("loading") as LoadImplements;

    const badges = ref({
      ['visitas_dia']: "",
      dicas: "",
      labels: [],
      ['visitas_mes']: "",
      users: "",
      graficos: {},
    });
    const dataInicial = ref("");
    const dataFinal = ref("");
    const modoAcesso = ref([0,0]);
    const plataforma = ref([0,0]);
    const modelo = ref([0,0, 0]);

    const optionsGrafico = 
    {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    }


    const reconstroiGraficos = () => {

      const graficoFaturamento = document.getElementById(
        '1' || "" 
      ) as HTMLCanvasElement;
      
      new ChartJs(graficoFaturamento, {
        type: 'doughnut',
        data: {
          labels: ['Online', 'Offline'],
          datasets: [
            {
              data: modoAcesso.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });


      const graficoTicket = document.getElementById(
        '2' || "" 
      ) as HTMLCanvasElement;
      
      new ChartJs(graficoTicket, {
        type: 'doughnut',
        data: {
          labels: ['Android', 'Apple'],
          datasets: [
            {
              data: plataforma.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });



      const graficoClientes = document.getElementById(
        '3' || ""
      ) as HTMLCanvasElement;
      new ChartJs(graficoClientes, {
        type: 'doughnut',
        data: {
          labels: ['Celular', 'Tablet', 'Ipad'],
          datasets: [
            {
              data: modelo.value,
              backgroundColor: ["#389897", "#5fd4d3", "#3a6b6b", "#3a6b6b"],
              borderColor: [`#389897`],
              borderWidth: 2,
            },
          ],
        },
        options: optionsGrafico
      });

    }

    onMounted(async () => {
      loader.open();
      const dataAtual = new Date();
      const dataAtualArray = dataAtual.toLocaleDateString().split("T")[0].split("/");
      dataInicial.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-01`;
      dataFinal.value = `${dataAtualArray[2]}-${dataAtualArray[1]}-${dataAtualArray[0]}`;
      try {
        const response = await GET(`dashboard/${dataInicial.value}/${dataFinal.value}`);
        badges.value = response;
        modoAcesso.value = [response.graficos.ONLINE, response.graficos.OFFLINE]
        plataforma.value = [response.graficos.ANDROID, response.graficos.APPLE]
        modelo.value = [response.graficos.CELULAR, response.graficos.TABLET, response.graficos.IPAD]
        reconstroiGraficos();
        loader.close();
      } catch (e) {
        loader.close();
        console.log(e);
      }
    });

    const filtrarGraficos = async () => {
      loader.open();
      try {
        const response = await GET(`dashboard/${dataInicial.value}/${dataFinal.value}`);
        modoAcesso.value = [response.graficos.ONLINE, response.graficos.OFFLINE]
        plataforma.value = [response.graficos.ANDROID, response.graficos.APPLE]
        modelo.value = [response.graficos.CELULAR, response.graficos.TABLET, response.graficos.IPAD]
        reconstroiGraficos();
        loader.close();
      } catch(e) {
        loader.close();
        console.log(e);
      }
      console.log(dataInicial.value)
      console.log(dataFinal.value)
    }

    return { event, badges, dataInicial, dataFinal, filtrarGraficos, modoAcesso, plataforma, modelo };
  },

  
});
